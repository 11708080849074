import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Booking from "../../components/Booking/Booking";
import About from "./About/About";
import Feature from "./Feature/Feature";
import Hero from "./Hero/Hero";
import Icons from "./Icons/Icons";
import "./homescreen.css";
import Meta from "../../components/Meta/Meta";
import Christmas from "./Christmas/Christmas";

const HomeScreen = () => {
  return (
    <>
      <Meta />
      <Hero />
      <Container fluid>
        <Christmas />
      </Container> 
      <Container fluid className="bgGray">
        <Row className="justify-content-md-center">
          <Col xs={12} md={5}>
            <Icons />
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row className="justify-content-md-center">
          <Col xs={12} md={5}>
            <About />
          </Col>
        </Row>
      </Container>

      <Container fluid className="bgGray">
        <Row className="justify-content-md-center">
          <Col xs={12} md={5}>
            <Feature />
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row className="justify-content-md-center">
          <Col xs={12} md={5}>
            <Booking />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default HomeScreen;
