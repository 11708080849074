import React from "react";
import { Row, Col } from "react-bootstrap";
import christmasImage from "./christmas_logo.jpg";
import "./christmas.css";
const Christmas = () => {
  return (
    <div className="block christmas">
      <Row className="justify-content-md-center">
        <Col md={12} lg={6} xl={4}>
          <div style={{ textAlign: "center" }}>
            <p>
              Rendelőnk december 19. és január 6. között zárva tart.
              <br /> Boldog Karácsonyt és új évet kívánunk!
            </p>
          </div>
          <div className="christmas_image_div">
            <img
              style={{ float: "right" }}
              src={christmasImage}
              alt="Oxydental karácsony"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Christmas;
